import axios from "axios";
import { add, differenceInDays, lightFormat } from "date-fns";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { CheckInput } from "./components/CheckInput";
import { Email } from "./components/Email";
import { SelectInput } from "./components/SelectInput";
import { TextInput } from "./components/TextInput";
import { compulsoryinsurance } from "./data/compulsoryinsurance";
import { compulsoryinsurance2 } from "./data/compulsoryinsurance2";
import { compulsoryinsurance3 } from "./data/compulsoryinsurance3";
import { constants } from "./data/constants";
import { doctorStatus } from "./data/doctorStatus";
import { empty } from "./data/empty";
import { insurance } from "./data/insurance";
import { insuranceExtension } from "./data/insuranceExtension";
import { insuranceExtension2 } from "./data/insuranceExtension2";
import { insuranceExtension3 } from "./data/insuranceExtension3";
import { insuranceExtension4 } from "./data/insuranceExtension4";
import { specjalizationData } from "./data/specjalizationData";
import { voluntaryRisk3 } from "./data/volunatryRisk3";
import { voluntary } from "./data/voluntary";
import { voluntaryRisk2 } from "./data/voluntaryRisk2";
import "./index.css";

const formatNumber = new Intl.NumberFormat().format;

function App() {
  const [currentDate, setCurrentDate] = useState(
    lightFormat(new Date(), "y-MM-dd")
  );
  const [maxDate, setMaxDate] = useState(
    lightFormat(add(new Date(), { days: 365 }), "y-MM-dd")
  );
  const [selectValue, setSelectValue] = useState({
    doctorStatus: {
      label: "",
      value: false,
    },
    numberPWZ: "",
    regonNumber: "",
    bookNumber: "",
    startDate: "2025-01-01",
    endDate: lightFormat(add(new Date("2025-01-01"), { days: 365 }), "y-MM-dd"),
    specjalization: {
      label: "",
      value: 1,
      class: 0,
    },
    insuranceExtensionValue: {
      label: "",
      amount: 0,
      contribution: 0,
    },
    chirurgicProcedure: false,
    insuranceType: "",
    sublimit: {
      label: "",
      amount: 0,
      contribution: 0,
    },
    doubleAmountAndContribution: false,
    insuranceExtension: false,
    pdf: false,
    emailAdress: "",
    agreement: false,
    agreement2: false,
    agreement3: false,
    agreement4: false,
    agreement5: false,
    agreement6: false,
    agreement7: false,
    agreement8: false,
    lawContext: false,
    sublimintAgreement: false,
  });
  let schema = yup.object().shape({
    doctorStatus: yup.object().shape({
      label: yup.string().required(),
      value: yup.boolean(),
    }),
    numberPWZ: yup.string().required().length(7),

    startDate: yup.string().required(),
    endDate: yup.string().required(),
    specjalization: yup.object().shape({
      label: yup.string(),
      value: yup.number(),
      class: yup.number(),
    }),
    insuranceExtensionValue: yup.object().shape({
      label: yup.string(),
      amount: yup.number(),
      contribution: yup.number(),
    }),
    chirurgicProcedure: yup.boolean(),
    insuranceType: yup.string().required(),
    insuranceExtension: yup.boolean(),

    emailAdress: yup.string().email().required(),
    agreement: yup.boolean().isTrue(),
    agreement1: yup.boolean().isTrue(),
    agreement2: yup.boolean().isTrue(),
    agreement3: yup.boolean().isTrue(),
    agreement4: yup.boolean().isTrue(),
    agreement5: yup.boolean().isTrue(),
    agreement6: yup.boolean().isTrue(),
    agreement7: yup.boolean().isTrue(),
    agreement8: yup.boolean().isTrue(),
  });

  let schema2 = yup.object().shape({
    doctorStatus: yup.object().shape({
      label: yup.string().required(),
      value: yup.boolean(),
    }),
    numberPWZ: yup.string().required().length(7),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    specjalization: yup.object().shape({
      label: yup.string(),
      value: yup.number(),
      class: yup.number(),
    }),
    insuranceExtensionValue: yup.object().shape({
      label: yup.string(),
      amount: yup.number(),
      contribution: yup.number(),
    }),
    chirurgicProcedure: yup.boolean(),
    insuranceType: yup.string().required(),
    insuranceExtension: yup.boolean(),
    pdf: yup.boolean(),
    emailAdress: yup.string().email().required(),
    agreement: yup.boolean().isTrue(),
    agreement1: yup.boolean().isTrue(),
    agreement2: yup.boolean().isTrue(),
    agreement3: yup.boolean().isTrue(),
    agreement4: yup.boolean().isTrue(),
    agreement5: yup.boolean().isTrue(),
    agreement6: yup.boolean().isTrue(),
    agreement7: yup.boolean().isTrue(),
    agreement8: yup.boolean().isTrue(),
  });
  let schemaLS = yup.object().shape({
    doctorStatus: yup.object().shape({
      label: yup.string().required(),
      value: yup.boolean(),
    }),
    numberPWZ: yup.string().required().length(7),

    startDate: yup.string().required(),
    endDate: yup.string().required(),
    specjalization: yup.object().shape({
      label: yup.string(),
      value: yup.number(),
      class: yup.number(),
    }),
    insuranceExtensionValue: yup.object().shape({
      label: yup.string(),
      amount: yup.number(),
      contribution: yup.number(),
    }),
    chirurgicProcedure: yup.boolean(),
    insuranceType: yup.string().required(),
    insuranceExtension: yup.boolean(),
    pdf: yup.boolean(),
    emailAdress: yup.string().email().required(),
    agreement: yup.boolean().isTrue(),
    agreement1: yup.boolean().isTrue(),
    agreement2: yup.boolean().isTrue(),
    agreement3: yup.boolean().isTrue(),
    agreement4: yup.boolean().isTrue(),
    agreement5: yup.boolean().isTrue(),
    agreement6: yup.boolean().isTrue(),
    agreement7: yup.boolean().isTrue(),
    agreement8: yup.boolean().isTrue(),
    lawContext: yup.boolean().isTrue(),
    sublimintAgreement: yup.boolean().isTrue(),
  });
  let schemaL = yup.object().shape({
    doctorStatus: yup.object().shape({
      label: yup.string().required(),
      value: yup.boolean(),
    }),
    numberPWZ: yup.string().required().length(7),

    startDate: yup.string().required(),
    endDate: yup.string().required(),
    specjalization: yup.object().shape({
      label: yup.string(),
      value: yup.number(),
      class: yup.number(),
    }),
    insuranceExtensionValue: yup.object().shape({
      label: yup.string(),
      amount: yup.number(),
      contribution: yup.number(),
    }),
    chirurgicProcedure: yup.boolean(),
    insuranceType: yup.string().required(),
    insuranceExtension: yup.boolean(),
    pdf: yup.boolean(),
    emailAdress: yup.string().email().required(),
    agreement: yup.boolean().isTrue(),
    agreement1: yup.boolean().isTrue(),
    agreement2: yup.boolean().isTrue(),
    agreement3: yup.boolean().isTrue(),
    agreement4: yup.boolean().isTrue(),
    agreement5: yup.boolean().isTrue(),
    agreement6: yup.boolean().isTrue(),
    agreement7: yup.boolean().isTrue(),
    agreement8: yup.boolean().isTrue(),
    lawContext: yup.boolean().isTrue(),
  });
  let schemaS = yup.object().shape({
    doctorStatus: yup.object().shape({
      label: yup.string().required(),
      value: yup.boolean(),
    }),
    numberPWZ: yup.string().required().length(7),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    specjalization: yup.object().shape({
      label: yup.string(),
      value: yup.number(),
      class: yup.number(),
    }),
    insuranceExtensionValue: yup.object().shape({
      label: yup.string(),
      amount: yup.number(),
      contribution: yup.number(),
    }),
    chirurgicProcedure: yup.boolean(),
    insuranceType: yup.string().required(),
    insuranceExtension: yup.boolean(),
    pdf: yup.boolean(),
    emailAdress: yup.string().email().required(),
    agreement: yup.boolean().isTrue(),
    agreement1: yup.boolean().isTrue(),
    agreement2: yup.boolean().isTrue(),
    agreement3: yup.boolean().isTrue(),
    agreement4: yup.boolean().isTrue(),
    agreement5: yup.boolean().isTrue(),
    agreement6: yup.boolean().isTrue(),
    agreement7: yup.boolean().isTrue(),
    agreement8: yup.boolean().isTrue(),

    sublimintAgreement: yup.boolean().isTrue(),
  });
  // DRUGA WALIDACJA
  let schemaS1 = yup.object().shape({
    doctorStatus: yup.object().shape({
      label: yup.string().required(),
      value: yup.boolean(),
    }),
    numberPWZ: yup.string().required().length(7),
    regonNumber: yup.string().required().length(9),
    bookNumber: yup.string().required().length(12),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    specjalization: yup.object().shape({
      label: yup.string(),
      value: yup.number(),
      class: yup.number(),
    }),
    insuranceExtensionValue: yup.object().shape({
      label: yup.string(),
      amount: yup.number(),
      contribution: yup.number(),
    }),
    chirurgicProcedure: yup.boolean(),
    insuranceType: yup.string().required(),
    insuranceExtension: yup.boolean(),
    pdf: yup.boolean(),
    emailAdress: yup.string().email().required(),
    agreement: yup.boolean().isTrue(),
    agreement1: yup.boolean().isTrue(),
    agreement2: yup.boolean().isTrue(),
    agreement3: yup.boolean().isTrue(),
    agreement4: yup.boolean().isTrue(),
    agreement5: yup.boolean().isTrue(),
    agreement6: yup.boolean().isTrue(),
    agreement7: yup.boolean().isTrue(),
    agreement8: yup.boolean().isTrue(),

    sublimintAgreement: yup.boolean().isTrue(),
  });
  let schemaL1 = yup.object().shape({
    doctorStatus: yup.object().shape({
      label: yup.string().required(),
      value: yup.boolean(),
    }),
    numberPWZ: yup.string().required().length(7),
    regonNumber: yup.string().required().length(9),
    bookNumber: yup.string().required().length(12),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    specjalization: yup.object().shape({
      label: yup.string(),
      value: yup.number(),
      class: yup.number(),
    }),
    insuranceExtensionValue: yup.object().shape({
      label: yup.string(),
      amount: yup.number(),
      contribution: yup.number(),
    }),
    chirurgicProcedure: yup.boolean(),
    insuranceType: yup.string().required(),
    insuranceExtension: yup.boolean(),
    pdf: yup.boolean(),
    emailAdress: yup.string().email().required(),
    agreement: yup.boolean().isTrue(),
    agreement1: yup.boolean().isTrue(),
    agreement2: yup.boolean().isTrue(),
    agreement3: yup.boolean().isTrue(),
    agreement4: yup.boolean().isTrue(),
    agreement5: yup.boolean().isTrue(),
    agreement6: yup.boolean().isTrue(),
    agreement7: yup.boolean().isTrue(),
    agreement8: yup.boolean().isTrue(),
    lawContext: yup.boolean().isTrue(),
  });
  let schemaLS1 = yup.object().shape({
    doctorStatus: yup.object().shape({
      label: yup.string().required(),
      value: yup.boolean(),
    }),
    numberPWZ: yup.string().required().length(7),
    regonNumber: yup.string().required().length(9),
    bookNumber: yup.string().required().length(12),
    startDate: yup.string().required(),
    endDate: yup.string().required(),
    specjalization: yup.object().shape({
      label: yup.string(),
      value: yup.number(),
      class: yup.number(),
    }),
    insuranceExtensionValue: yup.object().shape({
      label: yup.string(),
      amount: yup.number(),
      contribution: yup.number(),
    }),
    doubleAmountAndContribution: yup.boolean(),
    chirurgicProcedure: yup.boolean(),
    insuranceType: yup.string().required(),
    insuranceExtension: yup.boolean(),
    pdf: yup.boolean(),
    emailAdress: yup.string().email().required(),
    agreement: yup.boolean().isTrue(),
    agreement1: yup.boolean().isTrue(),
    agreement2: yup.boolean().isTrue(),
    agreement3: yup.boolean().isTrue(),
    agreement4: yup.boolean().isTrue(),
    agreement5: yup.boolean().isTrue(),
    agreement6: yup.boolean().isTrue(),
    agreement7: yup.boolean().isTrue(),
    agreement8: yup.boolean().isTrue(),
    lawContext: yup.boolean().isTrue(),
    sublimintAgreement: yup.boolean().isTrue(),
  });

  const insuranceContribution =
    selectValue.insuranceExtensionValue.contribution *
    (selectValue.doubleAmountAndContribution ? 2 : 1);

  const insuranceAmount =
    selectValue.insuranceExtensionValue.amount *
    (selectValue.doubleAmountAndContribution ? 2 : 1);

  const calculateContribution = () => {
    let firstDate = new Date(selectValue.startDate);
    let secondDate = new Date(selectValue.endDate);

    if (selectValue.insuranceExtension) {
      return (
        (differenceInDays(secondDate, firstDate) *
          (insuranceContribution * 2 + selectValue.sublimit.contribution)) /
        365
      );
    } else {
      return (
        (differenceInDays(secondDate, firstDate) *
          (insuranceContribution + selectValue.sublimit.contribution)) /
        365
      );
    }
  };

  useEffect(() => {
    if (selectValue.specjalization.class === 3) {
      setSelectValue((state) => ({ ...state, chirurgicProcedure: false }));
    }
  }, [selectValue.specjalization]);

  useEffect(() => {
    setSelectValue((state) => ({
      ...state,
      insuranceType: "",
      specjalization: {
        label: "",
        value: 0,
        class: 0,
      },
      sublimit: {
        label: "",
        amount: 0,
        contribution: 0,
      },
      insuranceExtensionValue: {
        label: "",
        amount: 0,
        contribution: 0,
      },
      chirurgicProcedure: false,
      insuranceExtension: false,
      lawContext: false,
      sublimintAgreement: false,
      regonNumber: "",
      bookNumber: "",
    }));
  }, [selectValue.doctorStatus]);

  useEffect(() => {
    setSelectValue((state) => ({
      ...state,
      sublimit: {
        label: "",
        amount: 0,
        contribution: 0,
      },
      chirurgicProcedure: false,
      lawContext: false,
      sublimintAgreement: false,

      insuranceExtensionValue: {
        label: "",
        amount: 0,
        contribution: 0,
      },
    }));
  }, [selectValue.insuranceType]);

  useEffect(() => {
    if (selectValue.insuranceType === "") {
      setSelectValue((state) => ({
        ...state,
        insuranceExtensionValue: empty.data[0],
      }));
    } else {
      if (selectValue.insuranceType === "Ubezpieczenie obowiązkowe") {
        if (
          selectValue.specjalization.class === 2 ||
          selectValue.chirurgicProcedure
        ) {
          setSelectValue((state) => ({
            ...state,
            insuranceExtensionValue: compulsoryinsurance2.data[0],
          }));
        } else if (selectValue.specjalization.class === 3) {
          setSelectValue((state) => ({
            ...state,

            insuranceExtensionValue: compulsoryinsurance3.data[0],
            chirurgicProcedure: false,
          }));
        } else {
          setSelectValue((state) => ({
            ...state,
            insuranceExtensionValue: compulsoryinsurance.data[0],
          }));
        }

        if (
          selectValue.specjalization.class === 1 &&
          !selectValue.chirurgicProcedure
        ) {
          setSelectValue((state) => ({
            ...state,
            insuranceExtensionValue: compulsoryinsurance.data[0],
          }));
        }
        if (
          selectValue.specjalization.class === 2 ||
          selectValue.chirurgicProcedure
        ) {
          setSelectValue((state) => ({
            ...state,
            insuranceExtensionValue: compulsoryinsurance2.data[0],
          }));
        }
      }
      if (selectValue.insuranceType === "Ubezpieczenia dobrowolne") {
        if (
          selectValue.specjalization.class === 2 ||
          selectValue.chirurgicProcedure
        ) {
          setSelectValue((state) => ({
            ...state,
            insuranceExtensionValue: voluntaryRisk2.data[0],
          }));
        } else if (selectValue.specjalization.class === 3) {
          console.log(voluntaryRisk3.data[0]);
          setSelectValue((state) => ({
            ...state,
            insuranceExtensionValue: voluntaryRisk3.data[0],
            chirurgicProcedure: false,
          }));
        } else {
          setSelectValue((state) => ({
            ...state,
            insuranceExtensionValue: voluntary.data[0],
          }));
        }
      }
    }
  }, [
    selectValue.specjalization,
    selectValue.insuranceType,
    selectValue.agreement,
    selectValue.agreement2,
  ]);

  useEffect(() => {
    if (selectValue.insuranceType === "Ubezpieczenia dobrowolne") {
      if (insuranceAmount === 50000)
        setSelectValue((state) => ({
          ...state,
          sublimit: insuranceExtension3.data[0],
        }));
      if (insuranceAmount > 150000)
        setSelectValue((state) => ({
          ...state,
          sublimit: insuranceExtension.data[0],
        }));
      if (insuranceAmount < 150000)
        setSelectValue((state) => ({
          ...state,
          sublimit: insuranceExtension4.data[0],
        }));
    } else {
      if (
        selectValue.doctorStatus.label === "" ||
        selectValue.insuranceExtensionValue.label === "" ||
        selectValue.specjalization.label === "" ||
        selectValue.insuranceType === ""
      ) {
        setSelectValue((state) => ({
          ...state,
          sublimit: empty.data[0],
        }));
      }

      if (selectValue.insuranceType === "Ubezpieczenie obowiązkowe") {
        setSelectValue((state) => ({
          ...state,
          sublimit: insuranceExtension2.data[0],
        }));
      }
    }
  }, [
    selectValue.insuranceExtensionValue,
    selectValue.insuranceType,
    selectValue.sublimintAgreement,
  ]);

  // useEffect(() => {
  //   if (selectValue.insuranceType === "Ubezpieczenie obowiązkowe") {
  //     setSelectValue((state) => ({
  //       ...state,
  //       insuranceExtensionValue: compulsoryinsurance.data[0],
  //       insuranceExtension: false,
  //     }));
  //   }
  //   if (selectValue.insuranceType === "Ubezpieczenia dobrowolne")
  //     setSelectValue((state) => ({
  //       ...state,
  //       insuranceExtensionValue: voluntary.data[0],
  //       insuranceExtension: false,
  //     }));
  // }, [selectValue.insuranceType]);

  useEffect(() => {
    setSelectValue((state) => ({
      ...state,
      doubleAmountAndContribution: false,
      insuranceExtension: false,
    }));

    if (
      selectValue.specjalization.label ===
        "Chirurgia plastyczna grupa ryzyka 3" &&
      selectValue.insuranceType === "Ubezpieczenia dobrowolne"
    ) {
      if (
        selectValue.specjalization.class === 2 ||
        selectValue.chirurgicProcedure
      ) {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: voluntaryRisk2.data[0],
          insuranceExtension: true,
        }));
      } else if (selectValue.specjalization.class === 3) {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: voluntaryRisk3.data[0],
          chirurgicProcedure: false,
          insuranceExtension: true,
        }));
      } else {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: voluntary.data[0],
          insuranceExtension: true,
        }));
      }
    }
  }, [selectValue.insuranceType]);

  const postData = () => {
    axios
      .post(
        // "https://generator-ubezpieczeniowy.dev10.pro/public/generate",
        "https://form.lewczuk.biz/generator/public/generate",
        {
          selectValue,
          calculateContribution: Math.round(calculateContribution()),
        },

        { responseType: `blob` }
      )
      .then((response) => {
        const file = new Blob([response.data], { type: "application/pdf" });
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);

        var url = window.URL.createObjectURL(response.data);
        var a = document.createElement("a");
        a.href = url;
        a.download = "file.pdf";

        a.click();
        a.remove();

        setTimeout(() => window.URL.revokeObjectURL(url), 100);
      });
  };

  const getProperValidation = () => {
    if (selectValue.doctorStatus.value === true) {
      if (
        selectValue.sublimit.amount > 0 &&
        insuranceAmount > constants.aestheticMedicineLimit &&
        selectValue.insuranceExtension
      ) {
        return schemaLS.isValidSync(selectValue);
      } else if (
        insuranceAmount >= constants.aestheticMedicineLimit &&
        selectValue.insuranceExtension
      ) {
        return schemaS.isValidSync(selectValue);
      } else if (selectValue.sublimit.amount > 0) {
        return schemaL.isValidSync(selectValue);
      } else {
        return schema.isValidSync(selectValue);
      }
    }
    if (selectValue.doctorStatus.value === false) {
      if (
        selectValue.sublimit.amount > 0 &&
        insuranceAmount >= constants.aestheticMedicineLimit &&
        selectValue.insuranceExtension
      ) {
        return schemaLS1.isValidSync(selectValue);
      } else if (
        insuranceAmount >= constants.aestheticMedicineLimit &&
        selectValue.insuranceExtension
      ) {
        return schemaS1.isValidSync(selectValue);
      } else if (selectValue.sublimit.amount > 0) {
        return schemaL1.isValidSync(selectValue);
      } else {
        return schema2.isValidSync(selectValue);
      }
    }
  };

  useEffect(() => {
    if (selectValue.chirurgicProcedure) {
      if (selectValue.insuranceType === "Ubezpieczenie obowiązkowe") {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: compulsoryinsurance2.data[0],
        }));
      }
      if (selectValue.insuranceType === "Ubezpieczenia dobrowolne") {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: voluntaryRisk2.data[0],
        }));
      }
    } else {
      if (selectValue.insuranceType === "Ubezpieczenie obowiązkowe") {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: compulsoryinsurance.data[0],
        }));
      }
      if (selectValue.insuranceType === "Ubezpieczenia dobrowolne") {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: voluntary.data[0],
        }));
      }
    }
    if (selectValue.insuranceType === "Ubezpieczenie obowiązkowe") {
      if (
        selectValue.specjalization.class === 2 ||
        selectValue.chirurgicProcedure
      ) {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: compulsoryinsurance2.data[0],
        }));
      } else if (selectValue.specjalization.class === 3) {
        setSelectValue((state) => ({
          ...state,
          insuranceExtension: false,
          insuranceExtensionValue: compulsoryinsurance3.data[0],
          chirurgicProcedure: false,
        }));
      } else {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: compulsoryinsurance.data[0],
        }));
      }

      if (
        selectValue.specjalization.class === 1 &&
        !selectValue.chirurgicProcedure
      ) {
        setSelectValue((state) => ({
          ...state,

          insuranceExtensionValue: compulsoryinsurance.data[0],
        }));
      }
      if (
        selectValue.specjalization.class === 2 ||
        selectValue.chirurgicProcedure
      ) {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: compulsoryinsurance2.data[0],
        }));
      }
    } else {
      if (
        selectValue.specjalization.class === 2 ||
        selectValue.chirurgicProcedure
      ) {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: voluntaryRisk2.data[0],
        }));
      } else if (selectValue.specjalization.class === 3) {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: voluntaryRisk3.data[0],
          chirurgicProcedure: false,
        }));
      } else {
        setSelectValue((state) => ({
          ...state,
          insuranceExtensionValue: voluntary.data[0],
        }));
      }
    }
  }, [selectValue.chirurgicProcedure]);

  const validatePWZ = (pwzNumber: string) => {
    let array = pwzNumber.split("");
    let arrayM = [1, 2, 3, 4, 5, 6];
    let sum = 0;
    for (let i = 0; i < 6; i++) {
      sum += Number(array[i + 1]) * arrayM[i];
    }

    if (sum % 11 === Number(array[0])) {
      return true;
    } else {
      return false;
    }
  };

  const validateRegon = (regon: string) => {
    let array = regon.split("");
    let arrayM = [8, 9, 2, 3, 4, 5, 6, 7];
    let sum = 0;
    for (let i = 0; i < 8; i++) {
      sum += Number(array[i]) * arrayM[i];
    }
    if (sum % 11 === 10) {
      if (0 === Number(array[8])) {
        return true;
      } else {
        return false;
      }
    }
    if (sum % 11 === Number(array[8])) {
      return true;
    } else {
      return false;
    }
  };

  const returnCorrectValidation = () => {
    if (selectValue.doctorStatus.value) {
      return getProperValidation() && validatePWZ(selectValue.numberPWZ);
    } else {
      return (
        getProperValidation() &&
        validatePWZ(selectValue.numberPWZ) &&
        validateRegon(selectValue.regonNumber)
      );
    }
  };

  return (
    <div className="outer">
      <div className="middle">
        <div className="inner">
          <div className="card2">
            <div className="header2">
              <h3>Ubezpieczenia OC lekarzy w 2025 roku</h3>
            </div>
            <div className="descryption">
              <span>Osoba do kontaktu:</span>
              <span>Tadeusz Lewczuk</span>
              <span>Telefon: 507 191 595</span>
              <span>e-mail: biuro@lewczuk.biz</span>
            </div>
            <div className="header-space">
              <h3 className="text-aligin-center">
                PROGRAM UBEZPIECZENIA ODPOWIEDZIALNOŚCI CYWILNEJ LEKARZY DLA
                CZŁONKÓW LUBELSKIEJ IZBY LEKARSKIEJ
              </h3>
            </div>

            <div className="list">
              <a
                className="margin"
                href={`${process.env.PUBLIC_URL}/A_WA50_0974020_1.pdf`}
              >
                {" "}
                Pełna treść Programu
              </a>
              <a
                className="margin"
                href={`${process.env.PUBLIC_URL}/WUOC(B)-1.pdf`}
                download
              >
                Warunki Ubezpieczenia Odpowiedzialności Cywilnej z tytułu
                wykonywania czynności zawodowych B
              </a>
              <a
                className="margin"
                href={`${process.env.PUBLIC_URL}/ADO.pdf`}
                download
              >
                Informacja o Adminstratorze Danych Osobowych
              </a>
            </div>
            <div>
              <div className="margin">
                <h5 className="text-aligin-center marginTop">
                  OBOWIĄZKOWE UBEZPIECZENIE ODPOWIEDZIALNOŚCI CYWILNEJ PODMIOTU
                  WYKONUJĄCEGO DZIAŁALNOŚĆ LECZNICZĄ
                </h5>
              </div>
              <div>
                <h5 className="text-aligin-center red">
                  dotyczy wyłącznie lekarzy wykonujących zawód w DOWOLNEJ FORMIE
                  PRAKTYKI PRYWATNEJ LUB GRUPOWEJ
                </h5>
              </div>
              <div className="list">
                <span className="margin">
                  1. Zakres ubezpieczenia zgodny z Rozporządzeniem Ministra
                  Finansów z dnia 29.04.2019 r. w sprawie obowiązkowego
                  ubezpieczenia odpowiedzialności cywilnej podmiotu wykonującego
                  działalność leczniczą
                </span>
                <span className="margin">
                  2.Minimalna suma gwarancyjna ubezpieczenia OC w okresie
                  ubezpieczenia nie dłuższym niż 12 miesięcy, wynosi
                  równowartość w złotych 75 000 EUR w odniesieniu do jednego
                  zdarzenia oraz 350 000 EUR w odniesieniu do wszystkich zdarzeń
                </span>
                <span className="margin">
                  3. Rozróżnia się trzy grupy ryzyka, według których
                  zaszeregowane są osoby wykonujące zawód medyczny w ramach
                  indywidualnej praktyki lekarskiej:
                </span>
                <span className="margin">
                  a) lekarze III grupy - specjalizacja: ortopedia, chirurgia
                  stomatologiczna, chirurgia naczyniowa, anestezjologia i
                  intensywna terapia, chirurgia klatki piersiowej, chirurgia
                  ogólna, chirurgia szczękowo-twarzowa, ginekologia onkologiczna
                  kardiochirurgia, neurochirurgia i traumatologia narządu ruchu,
                  położnictwo i ginekologia, chirurgia dziecięca, urologia,
                  chirurgia onkologiczna, chirurgia plastyczna, urologia
                  dziecięca, medycyna ratunkowa, neonatologia;
                </span>
                <span className="margin">
                  b) lekarze II grupy - specjalizacja: ortodoncja,
                  periodontologia, protetyka stomatologiczna, radioterapia
                  onkologiczna, stomatologia zachowawcza, medycyna sportowa,
                  stomatologia dziecięca
                </span>
                <span className="margin">
                  c) lekarze I grupy - specjalizacja: lekarze pozostałych
                  specjalności nie wymienionych w pkt a. lub pkt b.
                </span>
                <span className="margin">
                  4. W przypadku lekarzy zaszeregowanych do I grupy ryzyka, gdy
                  lekarz specjalista wykonuje zabiegi chirurgiczne, endoskopowe
                  lub z zakresu radiologii interwencyjnej nalicza się składkę
                  przewidzianą dla grupy II.
                </span>
                <span className="margin">
                  5. W przypadku posiadania dwóch lub większej liczby
                  specjalizacji, o zaszeregowaniu do odpowiedniej grupy decyduje
                  ta specjalizacja, która jest faktycznie wykonywana. Jeżeli
                  lekarz ma dwie lub więcej specjalizacji i wszystkie wykonuje,
                  to o przynależności do grupy decyduje ta specjalizacja, która
                  jest w grupie o najwyższej składce.
                </span>
                <span className="margin">
                  6. W przypadku braku specjalizacji, składkę ustala się według
                  I grupy ryzyka z zastrzeżeniem ust. 4.
                </span>
                <span className="margin">
                  7. W przypadku posiadania specjalizacji, a wykonywania w
                  praktyce innej specjalizacji (w trakcie specjalizowania się),
                  o przynależności do odpowiedniej grupy decyduje specjalizacja
                  faktycznie wykonywana.
                </span>
                <span className="margin">
                  8. Zakres ubezpieczenia zostaje rozszerzony o klauzulę Ochrony
                  Prawnej Ubezpieczonego w treści jak niżej: Suma ubezpieczenia
                  Ochrony Prawnej – 50 000 PLN
                </span>
                <span className="margin">9. Składka roczna:</span>
                <span className="margin">
                  a) lekarze III grupy ryzyka – 400 PLN
                </span>
                <span className="margin">
                  b) lekarze II grupy ryzyka – 200 PLN
                </span>
                <span className="margin">
                  c) lekarze I grupy ryzyka – 100 PLN
                </span>
                <span className="margin">
                  Zakres ubezpieczenia zostaje rozszerzony o klauzulę Ochrony
                  Prawnej Ubezpieczonego w treści jak niżej:
                </span>
                <span className="margin">
                  Suma ubezpieczenia Ochrony Prawnej – 50 000 PLN
                </span>
                <span className="margin">składka za klauzulę 35 PLN</span>
                <span className="margin">
                  10.Istnieje możliwość wykupienia klauzuli ochrony prawnej na
                  dodatkową sumę
                </span>
                <span className="margin">Sublimit /składka </span>
                <span className="margin">
                  Sublimit w PLN / osoba Składka roczna w PLN / osoba{" "}
                </span>
                <span className="margin">
                  Sublimit: 50 000 PLN oraz składka roczna: 70 PLN
                </span>
                <span className="margin">
                  Sublimit: 100 000 PLN oraz składka roczna: 130 PLN
                </span>
                <span className="margin">
                  <a href={`${process.env.PUBLIC_URL}/A_WA50_0974020_1.pdf`}>
                    Treść klauzuli jest dodstępna w pełnej treści programu (pdf){" "}
                  </a>
                </span>
                <span className="margin">
                  11. Istnieje możliwości wykupienia polisy ubezpieczenia OC
                  obowiązkowego z sumą gwarancyjną wyższą o 100%
                  <br />
                  Składka za rok/za ubezpieczonego
                </span>
                <span className="margin">
                  a) lekarze III grupy ryzyka – 800 PLN
                </span>
                <span className="margin">
                  b) lekarze II grupy ryzyka – 400 PLN
                </span>
                <span className="margin">
                  c) lekarze I grupy ryzyka – 200 PLN
                </span>
              </div>
              <div>
                <h5 className="header2 margin">
                  DOBROWOLNE UBEZPIECZENIE ODPOWIEDZIALNOŚCI CYWILNEJ OSÓB
                  WYKONUJĄCYCH ZAWÓD LEKARZA
                </h5>
              </div>
              <div className="list">
                <span className="margin">
                  1. Podstawę zawarcia umowy stanowią Warunki Ubezpieczenia
                  Odpowiedzialności Cywilnej z tytułu wykonywania czynności
                  zawodowych B.
                </span>
                <span className="margin">
                  2. Ochrona ubezpieczeniowa oparta jest o trigger act
                  committed.
                </span>
                <span className="margin">3. Ochrona obejmuje:</span>
                <span className="margin">
                  -szkody wynikłe z rażącego niedbalstwa,
                </span>
                <span className="margin">
                  -odpowiedzialność cywilną ubezpieczonego za podwykonawców,
                </span>
                <span className="margin">
                  odpowiedzialność cywilną za szkody w dokumentach,
                </span>
                <span className="margin">
                  -odpowiedzialność cywilną za szkody wynikłe z posiadania
                  mienia, w tym oc najemcy nieruchomości/ruchomości,
                </span>
                <span className="margin">
                  - odpowiedzialność cywilną za szkody wyrządzone osobom bliskim
                  wobec ubezpieczonego,
                </span>
                <span className="margin">
                  – odpowiedzialność cywilną za szkody wyrządzone personelowi
                  tej samej jednostki
                </span>
                <span className="margin">
                  4. Zakres ubezpieczenia zostaje rozszerzony o szkody w mieniu
                  i na osobie wynikłe z przeniesienia chorób zakaźnych (w tym
                  HIV i WZW) zgodnie z kl.4, sublimit do wysokości sumy
                  gwarancyjnej (w zależności od wybranego wariantu
                  ubezpieczenia), przy czym sublimit maksymalny wynosi 2.000.000
                  PLN na jeden i wszystkie wypadki w okresie
                  ubezpieczenia/osoba,
                </span>
                <span className="margin">
                  5. Zakres ubezpieczenia zostaje rozszerzony o szkody w mieniu
                  i na osobie powstałe w związku z przekroczeniem terminu
                  świadczeń medycznych, spowodowanego uchybieniami natury
                  organizacyjnej, administracyjnej, porządkowej: sublimit
                  25 000 PLN/osoba.
                </span>
                <span className="margin">
                  6. Zakres ubezpieczenia zostaje rozszerzony o szkody w mieniu
                  i na osobie powstałe w związku z używaniem aparatury medycznej
                  lub technicznej- zgodnie z kl.5, sublimit do wysokości sumy
                  gwarancyjnej (w zależności od wybranego wariantu
                  ubezpieczenia), przy czym sublimit maksymalny wynosi
                  2.000 000 PLN na jeden i wszystkie wypadki w okresie
                  ubezpieczenia/osoba.
                </span>
                <span className="margin">
                  7. Zakres ubezpieczenia zostaje rozszerzony o szkody na
                  osobie, w mieniu i czyste straty finansowe wynikłe z
                  naruszenia praw pacjenta, zgodnie z kl.3, sublimit do
                  wysokości sumy gwarancyjnej (w zależności od wybranego
                  wariantu ubezpieczenia), przy czym sublimit maksymalny wynosi
                  100 000 PLN na jeden i wszystkie wypadki w okresie
                  ubezpieczenia/osoba
                </span>
                <span className="margin">
                  8. Zakres ubezpieczenia zostaje rozszerzony o szkody
                  polegające na uszkodzeniu lub utracie mienia osobistego użytku
                  stanowiącego własność pacjentów: sublimit: 1 000 PLN/osoba.
                </span>
                <span className="margin">
                  9. Zakres może zostać (za opłatą dodatkowej składki)
                  rozszerzony o szkody w mieniu i na osobie powstałe w
                  następstwie zabiegów medycyny estetycznej lub chirurgii
                  plastycznej, jeżeli nie są one udzielane w przypadkach
                  będących następstwem wady wrodzonej, urazu, choroby lub
                  następstwem jej leczenia - sublimit do wysokości sumy
                  gwarancyjnej (w zależności od wybranego wariantu
                  ubezpieczenia), przy czym sublimit maksymalny wynosi
                  500 000 PLN na jeden i wszystkie wypadki w okresie
                  ubezpieczenia/osoba.
                </span>
                <span className="margin">
                  10. Ubezpieczenie dobrowolne jest zawierane w dwóch
                  wariantach:
                </span>
                <span className="margin">
                  a) Wariant I dotyczy w szczególności ubezpieczonych
                  pozostających w stosunku pracy w rozumieniu przepisów kodeksu
                  pracy, wykonujących zawód na podstawie zlecenia lub umowy o
                  dzieło, oraz nie wykonujących zawodu.
                </span>
                <span className="margin">
                  b) Wariant II dotyczy w szczególności ubezpieczonych
                  wykonujących zawód w formie indywidualnej lub grupowej
                  praktyki lekarskiej. W ramach wariantu II - STU ERGO HESTIA
                  S.A. udziela ochrony ubezpieczeniowej z tytułu
                  odpowiedzialności cywilnej za szkody wyrządzone przez
                  ubezpieczonego, który podlega obowiązkowemu ubezpieczeniu
                  odpowiedzialności cywilnej  w zakresie: ◦ nie objętym tym
                  obowiązkowym ubezpieczeniem odpowiedzialności cywilnej, ◦
                  objętym tym obowiązkowym ubezpieczeniem odpowiedzialności
                  cywilnej, jednak wyłącznie w zakresie szkód, które nie mogą
                  być zaspokojone z tytułu tego obowiązkowego ubezpieczenia
                  odpowiedzialności cywilnej z powodu wyczerpania sumy
                  gwarancyjnej (ubezpieczenie nadwyżkowe)
                </span>
                <span className="margin">
                  11. Warunkiem udzielenia ochrony ubezpieczeniowej określonej w
                  ust. 10 lit. b jest zawarcie umowy obowiązkowego ubezpieczenia
                  odpowiedzialności cywilnej podmiotu wykonującego działalność
                  leczniczą.
                </span>
                <span className="margin">
                  12. Rozróżnia się trzy grupy ryzyka, według których
                  zaszeregowane są osoby wykonujące zawód lekarza:
                </span>
                <span className="margin">
                  a. lekarze III grupy - specjalizacja: ortopedia, chirurgia
                  stomatologiczna, chirurgia naczyniowa, anestezjologia i
                  intensywna terapia, chirurgia klatki piersiowej, chirurgia
                  ogólna, chirurgia szczękowo-twarzowa, ginekologia onkologiczna
                  kardiochirurgia, neurochirurgia i traumatologia narządu ruchu,
                  położnictwo i ginekologia, chirurgia dziecięca, urologia,
                  chirurgia onkologiczna, chirurgia plastyczna, urologia
                  dziecięca, medycyna ratunkowa, neonatologia;
                </span>
                <span className="margin">
                  b. lekarze II grupy - specjalizacja: ortodoncja,
                  periodontologia, protetyka stomatologiczna, radioterapia
                  onkologiczna, stomatologia zachowawcza, medycyna sportowa,
                  stomatologia dziecięca
                </span>
                <span className="margin">
                  c. lekarze I grupy - specjalizacja: lekarze pozostałych
                  specjalności nie wymienionych w pkt a. lub pkt b.
                </span>
                <span className="margin">
                  13 W przypadku lekarzy zaszeregowanych do I grupy ryzyka, gdy
                  lekarz specjalista wykonuje zabiegi chirurgiczne, endoskopowe
                  lub z zakresu radiologii interwencyjnej nalicza się składkę
                  przewidzianą dla II grupy ryzyka.
                </span>
                <span className="margin">
                  14. W przypadku posiadania dwóch lub większej liczby
                  specjalizacji, o zaszeregowaniu do odpowiedniej grupy ryzyka
                  decyduje ta specjalizacja, która jest faktycznie wykonywana.
                  Jeżeli lekarz posiada dwie lub więcej specjalizacji i
                  wszystkie wykonuje, to o przynależności do grupy ryzyka
                  decyduje ta specjalizacja, która jest w grupie ryzyka o
                  najwyższej składce.
                </span>
                <span className="margin">
                  15. W przypadku braku specjalizacji, składkę ustala się według
                  I grupy ryzyka, z zastrzeżeniem ust. 14.
                </span>
                <span className="margin">
                  16. W przypadku posiadania specjalizacji, a wykonywania w
                  praktyce innej specjalizacji (w trakcie specjalizowania się),
                  o przynależności do odpowiedniej grupy ryzyka decyduje
                  specjalizacja faktycznie wykonywana.
                </span>
                <span className="margin">
                  17. Suma gwarancyjna na jeden i wszystkie wypadki oraz składka
                  roczna:
                </span>
                <span className="margin">a. I GRUPA RYZYKA</span>
                {voluntary.data.map((element) => (
                  <span className="margin">
                    Suma gwarancyjna {formatNumber(element.amount)} PLN –
                    składka {formatNumber(element.contribution)} PLN
                  </span>
                ))}

                <span className="margin">II GRUPA RYZYKA</span>
                {voluntaryRisk2.data.map((element) => (
                  <span className="margin">
                    Suma gwarancyjna {formatNumber(element.amount)} PLN –
                    składka {formatNumber(element.contribution)} PLN
                  </span>
                ))}

                <span className="margin">III GRUPA RYZYKA</span>
                {voluntaryRisk3.data.map((element) => (
                  <span className="margin">
                    Suma gwarancyjna {formatNumber(element.amount)} PLN –
                    składka {formatNumber(element.contribution)} PLN
                  </span>
                ))}

                <span className="margin">
                  18. Za rozszerzenie zakresu ubezpieczenia o szkody powstałe
                  wskutek zabiegów medycyny estetycznej lub chirurgii
                  plastycznej stosowanej w celach estetycznych ustala się
                  dodatkową składkę stanowiącą 100% składki określonej w ust.17.
                </span>
                <span className="margin">
                  19. W odniesieniu do ubezpieczenia dobrowolnego istnieje
                  możliwość wykupienia klauzuli ochrony prawnej. Sublimit
                  /składka zgodnie z poniższymi wartościami
                </span>
                <span className="margin">
                  Sublimit w PLN / osoba Skłądka roczna w PLN / osoba
                </span>
                <span className="margin">
                  Sublimit: 50 000 PLN oraz składka roczna: 70 PLN
                </span>
                <span className="margin">
                  Sublimit: 100 000 PLN oraz składka roczna: 130 PLN
                </span>

                <span className="margin">
                  Sublimit: 150 000 PLN oraz składka roczna: 200 PLN
                </span>
                <span className="margin">
                  <a href={`${process.env.PUBLIC_URL}/A_WA50_0974020_1.pdf`}>
                    Treść klauzuli jest dodstępna w pełnej treści programu (pdf){" "}
                  </a>
                </span>
                <span className="margin">
                  W przypadku wypełniania formularza jako osoba prowadząca
                  praktykę lekarską pole "Numer księgi rejestrowej praktyki
                  lekarskiej" należy uzupełnić numerem księgi rejestrowej
                  dostępnym pod tym linkiem:
                  <a
                    target="_blank"
                    href=" https://rpwdl.ezdrowie.gov.pl//RPZ/SearchEx?institutionType=L "
                  >
                    {" "}
                    https://rpwdl.ezdrowie.gov.pl//RPZ/SearchEx?institutionType=L 
                  </a>
                  Należy wpisać 12 cyfrowy ciąg cyfr
                </span>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="header">
              <h5>Zamów polisę ubezpieczeniową na 2025 rok!</h5>
            </div>
            <div className="alert">
              <h6>
                *Pola obramowane na czerwono są obowiązkowe. Należy je
                prawidłowo wypełnić
              </h6>
            </div>
            <div className="body">
              {/* FORMA PRAKTYKI LEKARSKIEJ */}
              <SelectInput
                calculateContribution={calculateContribution}
                labelContent={"Status lekarza"}
                placeHolderContent={"-- Proszę określić swój status --"}
                doctorStatus={doctorStatus}
                selectValue={selectValue}
                setSelectValue={setSelectValue}
                insurance={insurance}
                currentLabel={selectValue.doctorStatus.label}
              />
              {/* DANE */}
              <div className="inputs">
                <TextInput
                  inputLnth={7}
                  labelContent={"Numer PWZ"}
                  type={"tel"}
                  selectValue={selectValue}
                  setSelectValue={setSelectValue}
                  pattern={"/[0-9]/"}
                />
                {selectValue.doctorStatus.label ===
                "Wykonuje zawód lekarza (dentysty) w formie praktyki lekarskiej." ? (
                  <>
                    <TextInput
                      inputLnth={9}
                      labelContent={"Numer Regon własnej praktyki lekarskiej"}
                      type={"text"}
                      selectValue={selectValue}
                      setSelectValue={setSelectValue}
                    />
                    <TextInput
                      inputLnth={12}
                      labelContent={
                        "Numer ksiegi rejestrowej praktyki lekarskiej"
                      }
                      type={"text"}
                      selectValue={selectValue}
                      setSelectValue={setSelectValue}
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="inputs">
                <TextInput
                  inputLnth={11}
                  labelContent={"Początek ubezpieczenia"}
                  type={"date"}
                  selectValue={selectValue}
                  setSelectValue={setSelectValue}
                  currentDate={currentDate}
                  maxDate={maxDate}
                />
                <TextInput
                  inputLnth={11}
                  labelContent={"Koniec ubezpieczenia"}
                  type={"date"}
                  selectValue={selectValue}
                  setSelectValue={setSelectValue}
                  currentDate={currentDate}
                  maxDate={maxDate}
                />
                <div className="information"></div>
              </div>
              <div className="inputs">
                <SelectInput
                  currentLabel={selectValue.specjalization.label}
                  labelContent={
                    "Faktycznie wykonywana specjalizaja o największym stopniu ryzyka"
                  }
                  placeHolderContent={
                    "-- Proszę wybrać tytuł zawodowy / specjalizacje --"
                  }
                  specjalizationData={specjalizationData}
                  selectValue={selectValue}
                  setSelectValue={setSelectValue}
                  insurance={insurance}
                  calculateContribution={calculateContribution}
                />
                {selectValue.specjalization.class === 1 ? (
                  <CheckInput
                    currentValue={selectValue.chirurgicProcedure}
                    direction={"column"}
                    labelContent="Lekarz wykonuje zabiegi chirurgiczne, endoskopowe lub z zakresu radiologii interwencyjnej"
                    selectValue={selectValue}
                    setSelectValue={setSelectValue}
                    anchor="Lekarz wykonuje zabiegi chirurgiczne, endoskopowe lub z zakresu radiologii interwencyjnej"
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="inputs">
                <SelectInput
                  currentLabel={selectValue.insuranceType}
                  labelContent={"Rodzaj ubezpieczenia"}
                  calculateContribution={calculateContribution}
                  placeHolderContent={
                    "-- Prosze wybrać rodzaj ubezpieczenia --"
                  }
                  selectValue={selectValue}
                  setSelectValue={setSelectValue}
                  insurance={insurance}
                />
                {selectValue.insuranceType === "Ubezpieczenia dobrowolne" ? (
                  <CheckInput
                    currentValue={selectValue.insuranceExtension}
                    direction={"column"}
                    labelContent="Rozszerzenie ochrony o szkody powstałe wskutek zabiegów z zakresu medycyny estetycznej / chirurgii plastycznej (+100% składki)"
                    selectValue={selectValue}
                    setSelectValue={setSelectValue}
                    anchor={
                      "Rozszerzenie ochrony o szkody powstałe wskutek zabiegów z zakresu medycyny estetycznej / chirurgii plastycznej (+100% składki)"
                    }
                  />
                ) : (
                  <div></div>
                )}
              </div>

              <SelectInput
                calculateContribution={calculateContribution}
                currentLabel={selectValue.insuranceExtensionValue.label}
                labelContent={"Opcje ubezpieczenia"}
                placeHolderContent={"-- Proszę wybrać opcję ubezpieczenia --"}
                selectValue={selectValue}
                setSelectValue={setSelectValue}
                insurance={insurance}
              />
              {selectValue.insuranceExtensionValue.amount > 0 &&
                selectValue.insuranceType === "Ubezpieczenie obowiązkowe" && (
                  <CheckInput
                    currentValue={selectValue.doubleAmountAndContribution}
                    direction={"row"}
                    labelContent={"Podwój sumę gwarancyjną (+100% składki)"}
                    selectValue={selectValue}
                    setSelectValue={setSelectValue}
                    anchor={"Podwój sumę gwarancyjną (+100% składki)"}
                  />
                )}

              {insuranceAmount > constants.aestheticMedicineLimit &&
              selectValue.insuranceExtension ? (
                <CheckInput
                  currentValue={selectValue.sublimintAgreement}
                  direction={"row"}
                  labelContent={
                    "Rozumiem że sublimit dla zabiegów z zakresu medycyny estetycznej/ chirurgi plastycznej udzielanych w celach estetycznych wynosi 500.000 PLN"
                  }
                  selectValue={selectValue}
                  setSelectValue={setSelectValue}
                  anchor={
                    "Rozumiem że sublimit dla zabiegów z zakresu medycyny estetycznej/ chirurgi plastycznej udzielanych w celach estetycznych wynosi 500.000 PLN"
                  }
                />
              ) : (
                ""
              )}
            </div>
            <div style={{ marginBottom: "10px" }}>
              <SelectInput
                calculateContribution={calculateContribution}
                currentLabel={selectValue.sublimit.label}
                labelContent={
                  "Rozszerzenie ubezpieczenia (klauzula ochrony prawnej)"
                }
                placeHolderContent={""}
                selectValue={selectValue}
                setSelectValue={setSelectValue}
                insurance={insurance}
              />
              {selectValue.sublimit.amount > 0 ? (
                <CheckInput
                  currentValue={selectValue.lawContext}
                  labelContent="Oświadczam, że przeczytałem i rozumiem treść klauzuli ochrony prawnej, w szczególności obowiązki informacyjne wobec ubezpieczyciela."
                  direction="row"
                  setSelectValue={setSelectValue}
                  selectValue={selectValue}
                  anchor="Oświadczam, że przeczytałem i rozumiem treść klauzuli ochrony prawnej, w szczególności obowiązki informacyjne wobec ubezpieczyciela."
                />
              ) : (
                ""
              )}
            </div>
            <div>
              <Email
                inputLnth={50}
                labelContent={"Podaj email"}
                type={"email"}
                selectValue={selectValue}
                setSelectValue={setSelectValue}
              />

              <CheckInput
                currentValue={selectValue.agreement}
                direction={"row"}
                labelContent={`Rozumiem, iż warunkiem objęcia ubezpieczeniem od dnia ${selectValue.startDate}  jest wpłata składki najpóźniej w dniu ${selectValue.startDate} . W przypadku późniejszej wpłaty okres ubezpieczenia rozpocznie się w dniu dokonania tej wpłaty`}
                selectValue={selectValue}
                setSelectValue={setSelectValue}
                anchor={`Rozumiem, iż warunkiem objęcia ubezpieczeniem od dnia ${selectValue.startDate}  jest wpłata składki najpóźniej w dniu ${selectValue.startDate} . W przypadku późniejszej wpłaty okres ubezpieczenia rozpocznie się w dniu dokonania tej wpłaty`}
              />
            </div>

            <div>
              <CheckInput
                currentValue={selectValue.agreement2}
                direction="row"
                labelContent="Oświadczam, iż przed zawarciem umowy ubezpieczenia otrzymałem(am) i zapoznałem(am) się z: OWU (link) oraz z informacjami o produkcie ubezpieczeniowym (link). Pobranie tych dokumentów jest warunkiem przystąpienia do ubezpieczenia."
                selectValue={selectValue}
                anchor={
                  <>
                    Oświadczam, iż przed zawarciem umowy ubezpieczenia
                    otrzymałem(am) i zapoznałem(am) się z: OWU
                    <a
                      href={`${process.env.PUBLIC_URL}/WUOC(B)-2.pdf`}
                      download
                    >
                      (link)
                    </a>
                    oraz z informacjami o produkcie ubezpieczeniowym
                    <a
                      href={`${process.env.PUBLIC_URL}/WUOC(B)-2.pdf`}
                      download
                    >
                      (link)
                    </a>{" "}
                    Pobranie tych dokumentów jest warunkiem przystąpienia do
                    ubezpieczenia.{" "}
                  </>
                }
                setSelectValue={setSelectValue}
              />
            </div>
            <div>
              <CheckInput
                currentValue={selectValue.agreement3}
                direction="row"
                labelContent="Oświadczam, że otrzymałam/em informację o przetwarzaniu moich danych osobowych, zawartą w dostarczonym mi dokumencie Informacja o Administratorze danych osobowych  (link)."
                selectValue={selectValue}
                setSelectValue={setSelectValue}
                anchor={
                  <>
                    Oświadczam, że otrzymałam/em informację o przetwarzaniu
                    moich danych osobowych, zawartą w dostarczonym mi dokumencie
                    Informacja o Administratorze danych osobowych{" "}
                    <a
                      href={`${process.env.PUBLIC_URL}/ADO-2 (1).pdf`}
                      download
                    >
                      (link).
                    </a>
                  </>
                }
              />
            </div>
            <div>
              <CheckInput
                currentValue={selectValue.agreement4}
                direction="row"
                labelContent="Oświadczam, że przed zawarciem umowy ubezpieczenia uzyskałem/am informację, że do umowy ubezpieczenia mają zastosowanie przepisy prawa polskiego."
                selectValue={selectValue}
                anchor={
                  "Oświadczam, że przed zawarciem umowy ubezpieczenia uzyskałem/am informację, że do umowy ubezpieczenia mają zastosowanie przepisy prawa polskiego."
                }
                setSelectValue={setSelectValue}
              />
            </div>
            <div>
              <CheckInput
                currentValue={selectValue.agreement5}
                direction="row"
                labelContent="Oświadczam, że niniejsza umowa ubezpieczenia spełnia moje wymagania i potrzeby przy uwzględnieniu wysokości składki jaką jestem skłonny/a zapłacić. Zawieram ją w wyniku mojej świadomej decyzji."
                selectValue={selectValue}
                setSelectValue={setSelectValue}
                anchor={
                  "Oświadczam, że niniejsza umowa ubezpieczenia spełnia moje wymagania i potrzeby przy uwzględnieniu wysokości składki jaką jestem skłonny/a zapłacić. Zawieram ją w wyniku mojej świadomej decyzji."
                }
              />
            </div>
            <div>
              <CheckInput
                currentValue={selectValue.agreement6}
                direction="row"
                labelContent="Wyrażam zgodę na przesyłanie informacji i dokumentów, w tym OWU oraz dokumentu zawierającego informacje o produkcie, a także informacji, o których mowa w art. 9 ust.1 ustawy o dystrybucji ubezpieczeń, które mają związek z wnioskowaną lub zawartą umową ubezpieczenia w STU Ergo Hestia SA za pośrednictwem środków komunikacji elektronicznej, w tym wiadomości e-mail, na wskazany przeze mnie adres mailowy. W razie zmiany podanych przeze mnie danych kontaktowych, zobowiązuję się uaktualniać moje dane, dzięki którym będę otrzymywać informacje za pośrednictwem środków komunikacji elektronicznej."
                selectValue={selectValue}
                setSelectValue={setSelectValue}
                anchor={
                  "Wyrażam zgodę na przesyłanie informacji i dokumentów, w tym OWU oraz dokumentu zawierającego informacje o produkcie, a także informacji, o których mowa w art. 9 ust.1 ustawy o dystrybucji ubezpieczeń, które mają związek z wnioskowaną lub zawartą umową ubezpieczenia w STU Ergo Hestia SA za pośrednictwem środków komunikacji elektronicznej, w tym wiadomości e-mail, na wskazany przeze mnie adres mailowy. W razie zmiany podanych przeze mnie danych kontaktowych, zobowiązuję się uaktualniać moje dane, dzięki którym będę otrzymywać informacje za pośrednictwem środków komunikacji elektronicznej."
                }
              />
            </div>
            <div>
              <CheckInput
                currentValue={selectValue.agreement7}
                direction="row"
                labelContent="Wyrażam zgodę na przetwarzanie danych osobowych, podanych w formularzu/wniosku o ubezpieczenie, przez Lewczuk Sp. z o.o., KRS 0000206033 (jako administratora danych) w celu przesyłania informacji i dokumentów za pośrednictwem środków komunikacji elektronicznej niezbędnych do zawarcia, wykonywania (obsługi) i odnowienia umowy ubezpieczenia, w tym: - kontaktu poprzez email (poczta elektroniczna), - kontaktu poprzez sms i telefon (wiadomości tekstowe i połączenia głosowe). Zgody powyższej udzielam dobrowolnie i mogę odwołać ją w każdym czasie wysyłając e-mail na adres biuro@lewczuk.biz, listem na adres Lewczuk Sp. z o.o., Brzoskwiniowa 1, 20-142 Lublin albo na stronie internetowej www.lewczuk.biz. Wycofanie zgody wywiera skutki prawne od dnia jej wycofania."
                selectValue={selectValue}
                setSelectValue={setSelectValue}
                anchor={
                  "Wyrażam zgodę na przetwarzanie danych osobowych, podanych w formularzu/wniosku o ubezpieczenie, przez Lewczuk Sp. z o.o., KRS 0000206033 (jako administratora danych) w celu przesyłania informacji i dokumentów za pośrednictwem środków komunikacji elektronicznej niezbędnych do zawarcia, wykonywania (obsługi) i odnowienia umowy ubezpieczenia, w tym: - kontaktu poprzez email (poczta elektroniczna), - kontaktu poprzez sms i telefon (wiadomości tekstowe i połączenia głosowe).Zgody powyższej udzielam dobrowolnie i mogę odwołać ją w każdym czasie wysyłając e-mail na adres biuro@lewczuk.biz, listem na adres Lewczuk Sp. z o.o., Brzoskwiniowa 1, 20-142 Lublin albo na stronie internetowej www.lewczuk.biz. Wycofanie zgody wywiera skutki prawne od dnia jej wycofania."
                }
              />
            </div>
            <div>
              <CheckInput
                currentValue={selectValue.agreement8}
                direction="row"
                labelContent="Upoważniam brokera ubezpieczeniowego Lewczuk Sp. z o.o., zezwolenie PUNU nr 187/97 do wykonywania czynności pośrednictwa ubezpieczeniowego na moją rzecz przy zawieraniu i wykonywaniu wnioskowanej przeze mnie umowy ubezpieczenia odpowiedzialności cywilnej"
                selectValue={selectValue}
                setSelectValue={setSelectValue}
                anchor={
                  "Upoważniam brokera ubezpieczeniowego Lewczuk Sp. z o.o., zezwolenie PUNU nr 187/97 do wykonywania czynności pośrednictwa ubezpieczeniowego na moją rzecz przy zawieraniu i wykonywaniu wnioskowanej przeze mnie umowy ubezpieczenia odpowiedzialności cywilnej"
                }
              />
            </div>
            {returnCorrectValidation() ? (
              <div className="buttondiv">
                <div className="payment">
                  <h5>Do zapłaty: {Math.round(calculateContribution())} zł</h5>
                </div>
                <div className="button-space">
                  <button className="button" onClick={() => postData()}>
                    Generuj oferte
                  </button>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
